.qnr-right-panel input[type='text'],
select,
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.qnr-right-panel input[type='submit'] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.qnr-right-panel input[type='submit']:hover {
  background-color: #45a049;
}

.qnr-right-panel div {
  border-radius: 5px;
  padding: 20px;
}

.qnr-right-panel label {
  float: left;
  margin: 1px;
}

.qnr-right-panel h3 {
  font-size: 18px;
  padding-left: 20px;
  font-weight: 500;
}

.qnr-right-panel input[type='checkbox'] {
  float: left;
}
