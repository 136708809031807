.container {
  width: 100%;
}

header {
  background-color: #333e49;
  color: #fff;
  font-weight: 600;
  padding: 1rem 3rem;
  height: 8vh;
  display: flex;
  align-items: center;
}

.header__app-title {
  font-size: 16pt;
  font-weight: 600;
}

nav {
  grid-area: nav;
  padding: 10px;
}

nav ul {
  list-style: none;
}

nav li {
  font-size: 16px;
  font-weight: 500;
  color: #145878;
  margin-bottom: 12px;
}

nav a {
  text-decoration: none;
}

main {
  grid-area: content;
  padding: 10px;
}
