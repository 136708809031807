.questions-panel {
  display: grid;
  padding: 15px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr, 1fr, 3fr;
}

#panel-header {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

#panel-title {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
}

.arrow {
  border: none;
  background: transparent;
  font-size: 1.1em;
  justify-self: start;
}

#arrows-container {
  padding: 0px;
  margin: 0px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
}

#setup-default-questions {
  border: none;
  text-decoration: underline;
  background: transparent;
  grid-column: 2/3;
}

#panel-button-container {
  display: grid;
  justify-items: right;
}

.questions-container {
  margin-top: 10px;
  display: grid;
  max-height: 72vh;
  overflow-y: auto;
  border-radius: 0px;
}

.questionlist-item {
  padding: 8px;
  width: 100%;
  text-align: left;
  border: 0px;
  background-color: transparent;
}

.questionlist-item:hover {
  background-color: rgb(219, 219, 219);
}

.questionlist-item-selected {
  background-color: rgb(201, 200, 200);
}
