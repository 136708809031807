h2 {
  font-weight: 500;
  line-height: 1.1;
}

.layoutHeader {
  padding: 0 13px 10px 7px;
  position: relative;
  height: 48px;
  border-bottom: 1px solid #bfbfbf;
}

.layoutLabel {
  margin-bottom: -4px;
  margin-left: 3px;
  color: #37474f;
  font-size: 20px;
}

.layoutTitle {
  display: inline-block;
  top: 2px;
}
