.qnr-center-panel {
  padding: 10px 20px;
  overflow-y: auto;
  max-height: 85vh;
}

.qnr-center-panel .actionRow {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: [col-1] auto [col-2] 150px;
  grid-template-rows: [row-1] auto;
}

.qnr-center-panel .actionRow button {
  margin: 0 !important;
  grid-column-start: col-2;
}

.qnr-center-panel .rdg-cell-input {
  color: #455a64;
  min-height: 25px;
  padding: 3px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-color: rgb(207, 216, 220) !important;
  border: 0.8px solid;
  display: block;
  width: 100%;
  -webkit-appearance: none;
  box-sizing: border-box;
  margin: 5px 0;
}

.qnr-center-panel .tool-tip {
  font-size: 13px;
}

.qnr-center-panel .tool-tip-anchor {
  padding: 0;
  font-size: 20px;
  border: none;
  background: none;
  margin: 0 18px 0 13px;
}

.qnr-center-panel .tool-tip-anchor:nth-child(odd) {
  margin: 0 13px 0 18px !important;
}

.qnr-center-panel .error {
  color: red;
}

.qnr-center-panel .error-input {
  border: 1px solid red !important;
}
