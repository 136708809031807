.container-qnr {
  display: grid;
  grid-template-areas:
    'leftHeader centerHeader rightHeader'
    'leftContent centerContent rightContet';
  grid-template-columns: 1fr 3fr 1fr;
}

.left-header {
    grid-area: leftHeader;
}

.center-header {
    grid-area: centerHeader;
}

.right-header {
    grid-area: rightHeader;
}

.left-sidebar,
.right-sidebar {
  border-bottom: 1px solid #bfbfbf;
}

.center-grid {
  border: 1px solid #bfbfbf;
  border-top: none;
  min-height: 85vh;
}
