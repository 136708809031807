.typeahead-container {
  width: 100%;
  display: grid;
  grid-template-columns: 89% 12%;
  grid-template-rows: 0fr 0fr 0fr;
  position: relative;
}

.typeahead-input {
  border-radius: 8px;
  border: 1px solid #ccc;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
  height: 100%;
  padding: 10px;
  grid-column: 1/2;
  grid-row: 1/2;
}

.typeahead-button {
  border-color: lightgray;
  border-radius: 8px;
  border: 1px solid #ccc !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 100%;
  padding: 0px;
  padding-top: 4px;
  grid-column: 2/3;
  grid-row: 1/2;
}

.typeahead-optionlist {
  max-height: 15.1rem;
  overflow-y: auto;
  border: 1px solid lightgray;
  border-top: 0px;
  margin-top: 0px;
  display: none;
  list-style: none;
  padding: 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  top: 38px;
  grid-column: 1/3;
  grid-row: 1/3;
  width: 100%;
}

.typeahead-button:hover {
  background-color: rgb(219, 219, 219);
}

.typeahead-button:active {
  background-color: rgb(190, 190, 190);
}

.typeahead-button-active {
  border-bottom-right-radius: 0px;
}

.typeahead-input-active {
  border-bottom-left-radius: 0px;
}

.optionlist-item {
  padding: 8px;
  width: 100%;
  text-align: left;
  border: 0px;
  background-color: transparent;
}

.optionlist-item:hover {
  background-color: rgb(219, 219, 219);
}

.optionlist-item-selected {
  background-color: rgb(201, 200, 200);
}

.typeahead-optionlist-active {
  display: block;
}
