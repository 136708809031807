* {
    box-sizing: border-box;
}

body {
  margin: 0;
  background: #f2f2f2;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn {
    border-width: 0;
    padding: 0.5rem;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
}

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.bottom-0 {
    bottom: 0;
}

.right-0 {
    right: 0;
}

/*#region React-Data-Grid Overrides*/
.rdg {
  block-size: auto !important;
}

.rdg-header-row {
  background-color: #cecece;
}

.rdg-header-row .rdg-cell {
  font-size: 13px;
  font-weight: bold;
  color: #333e48;
  padding: 0 8px;
}

.rdg-cell {
  border-right: 1px solid #d9d9d9;
  border-top: none;
  border-bottom: none;
  border-left: none;
}

.rdg-row .rdg-cell:last-child {
  border: none;
}

.rdg-row:nth-child(odd) {
  background-color: #f1f1f1;
}

.rdg-summary-row {
  background-color: #cecece !important;
}
/*#endregion*/

/*#region Athena Button Styling*/
.dl-primary-button {
  background: #00a0af;
  color: #fff;
  font-weight: 500;
  min-width: 70px;
}

.dl-primary-button:hover {
  background: #74dcc1;
  box-shadow: 0 4px 6px #78909c;
  color: #fff;
  border: none;
  outline: none;
}

.dl-primary-button:active {
    background: #007379;
}
/*#endregion*/

.button-row {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: flex-end;
    gap: 10px;
}

/*overlay*/
.overlay {
  display: block;
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 1;
  background: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  -webkit-transition: all 1.25s ease-in-out;
  -moz-transition: all 1.25s ease-in-out;
  -o-transition: all 1.25s ease-in-out;
  transition: all 1.25s ease-in-out;
}

/*end overlay*/

/*Generic Modal Window*/
.o-modal {
  height: 80%;
  background-color: #ffffff;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  z-index: 100000;
}

.c-modal_header {
  background-color: #999999;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  height: 35px;
}

.c-modal_header span {
  font-size: 16px;
  color: #ffffff;
  position: relative;
  top: 6px;
  left: 20px;
}

.c-modal_header .c-modal_close {
  position: relative;
  float: right;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.c-modal_footer,
.commentSurveyPanelModalFooter {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  height: 85px;
  background-color: #ffffff;
}

.c-modal_footer hr {
  margin-top: 0;
  margin-bottom: 25px;
  border-top: 1px solid #eee;
  margin-left: 20px;
  margin-right: 20px;
}

.c-modal_details {
  display: inline-block;
  padding-left: 10px;
  padding-top: 5px;
}

.c-modal_buttons {
  display: flex;
  justify-content: space-evenly; /*NOTE: space-evenly is only supported by Chrome, will not work in Edge or IE */
  padding-bottom: 10px;
}

.c-modal-primary-button {
  background: #196a8b !important;
  color: #ffffff !important;
  padding: 0.5rem;
  margin: 0 1.7rem 0.5rem 1.7rem !important;
  outline: none !important;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #bfbfbf;
  font-weight: 500;
  min-width: 90px;
}

.c-modal-secondary-button {
  background: #ffffff !important;
  color: #196a8b !important;
  margin: 0 1.7rem 0.5rem 1.7rem !important;
  padding: 0.5rem;
  outline: none !important;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #196a8b;
  font-weight: 500;
  min-width: 90px;
}

.c-modal_body {
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 80%;
}

.c-modal_body label {
  margin-top: 6px;
}

.c-modal_subtext {
  color: #999999;
  text-align: center;
  font-size: 12px;
}

.c-modal_backbtn {
  position: relative;
  float: left;
  top: 6px;
  left: 5px;
  cursor: pointer;
}

/* end generic modal */
